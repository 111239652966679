/* You can add global styles to this file, and also import other style files */

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,300;0,400;0,600;0,700;1,400;1,600;1,700&display=swap');

@import '@angular/material/theming';
@import '@angular/material/prebuilt-themes/indigo-pink.css';
// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$font-family: 'Nunito Sans', sans-serif;

$custom-typography: mat-typography-config(
  $font-family: $font-family,
  $headline: mat-typography-level(32px, 48px, 700),
  $body-1: mat-typography-level(16px, 24px, 500)
);
.encourage-contribute{
  width: 24px;
  display: block;
  float: left;
  padding-right: 8px;
}
.custom-add-btn{
  line-height: 18px !important;
  height: auto !important;
  min-height: 36px;
  padding-bottom: 5px !important;
  padding-top: 5px !important;
  max-width: 150px !important;
  width: -webkit-fill-available;
}
.main-error{
  .cancel-popup {
    margin-right: 10px;
  }
  p {
    margin-top: 5px;
    margin-bottom: 20px;
    line-height: 1.1;
    font-size: 13px;
  }
}
.add-new-fireworks-box {
  border: solid 1px #ccc;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  gap: 10px;
  align-items: center;
}
.login-page {
  .col-md-8 {
    margin: 0 auto;
  }
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    .card-header {
      padding: 0.75rem 1.25rem;
      margin-bottom: 0;
      background-color: rgba(0, 0, 0, 0.03);
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    }
    .card-body {
      flex: 1 1 auto;
      min-height: 1px;
      padding: 20px 90px 30px 0px;
      .social-icons {
        font-size: 2em;
        .login-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          a{
            font-size: 22px;
            display: flex;
            align-items: center;
            text-decoration: none;
            img {
              width: 30px;
            }
            span {
              font-size: 22px;
              margin-left: 25px;
            }
          }
        }
        .apple{
          a{
            span{
              margin: 0px !important;
            }
          }
        }
      }
    }
  }
}
.search-area{
  .mat-mdc-form-field{
    width: 100%;
  }
}
.create-form-search {
  display: flex;
  align-items: center;
  .mat-mdc-form-field {
    width: 100%;
    max-width: 330px;
  }
  button {
    background-color: #3490dc;
    color: #fff !important;
    margin-left: 10px;
    position: relative;
    top: -10px;
  }
}
.serach-box {
  display: flex;
  background-clip: padding-box;
  margin-bottom: 1rem;
  border: 1px solid #ced4da;
  border-right: none;
  border-radius: 4px 0px 0px 4px;
  input {
    width: 100%;
    border: none;
    padding: 7px 14px;
    color: #495057;
    background-color: #fff;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.6;
    font-family: $font-family;
    border: 1px solid #ced4da;
  }
  textarea {
    width: 100%;
    border: none;
    padding: 7px 14px;
    color: #495057;
    background-color: #fff;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.6;
    height: 100px;
    font-family: $font-family;
  }
  input[type="button"] {
    background-color: #227dc7;
    border-color: #227dc7;
    color: #fff;
    border: solid 1px #227dc7;
    border-radius: 0px 4px 4px 0px;
    padding: 0px 14px;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.6;
    width: 100%;
    max-width: 100px;
    font-family: $font-family;
  }
}
.tags {
  a {
    display: inline-block;
    background-color: #a436f2;
    color: #fff !important;
    padding: 2px 8px;
    border-radius: 5px;
    margin-bottom: 4px;
    text-decoration: none !important;
    font-size: 12px;
    margin-right: 3px;
    &:first-child {
      background-color: #3490dc;
    }
  }
}
/* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
#mat-tab-content-0-3 .comment-table {
  display: none;
}
body {
  margin: 0;
  font-family: $font-family !important;
  background-color: #f8fafc;
  font-size: 0.9rem !important;
  font-weight: 400 !important;
  line-height: 1.6 !important;
  color: #212529;
  letter-spacing: normal;
}
.mat-toolbar, .mat-toolbar h1, .mat-toolbar h2, .mat-toolbar h3, .mat-toolbar h4, .mat-toolbar h5, .mat-toolbar h6, .mat-expansion-panel-header, .mat-mdc-checkbox, .mat-mdc-form-field {
  font-family: $font-family !important;
  letter-spacing: normal !important;
}

.mat-h2, .mat-headline-6, .mat-typography h2, .mat-h3, .mat-subtitle-1, .mat-typography h3, .mat-mdc-table, .mat-expansion-panel-content, .mat-mdc-select, .mdc-button, .mat-mdc-dialog-container .mdc-dialog__content, .mat-mdc-floating-label, .mdc-tab, .mat-mdc-paginator, .mdc-data-table__content, .mat-mdc-standard-chip{
  font-family: $font-family !important;
  letter-spacing: normal !important;
}
mat-chip-listbox {
  margin-bottom: 20px;
}
.mat-body, .mat-body-2, .mat-typography .mat-body, .mat-typography .mat-body-2, .mat-typography, .mdc-form-field, .mdc-data-table__header-cell, .mdc-data-table__cell {
  font-family: $font-family !important;
  letter-spacing: normal !important;
}
.mat-mdc-header-cell {
  color: #212529;
  font-weight: 700;
}
.mat-expansion-panel-header {
  font-weight: 400;
}
a:not(.btn) {
  color: inherit;
  text-decoration: underline;
}
.row {
  margin-left: -15px;
  margin-right: -15px;
  display: flex;
  flex-flow: wrap;
}
.col-xl, .col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg, .col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md, .col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm, .col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col, .col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.pl-0{
  padding-left: 0;
}
.pr-0{
  padding-right: 0;
}
.pt-0{
  padding-top: 0;
}
.pb-0{
  padding-bottom: 0;
}
.p-0{
  padding: 0;
}
.mat-typography h3,h3{
    font-size: 1.575rem !important;
}
.w-100{
  width:100%;
}
.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12{
  padding-left: 15px;
  padding-right: 15px;
  box-sizing: border-box;
}
.clearfix{
  &::after {
    display: block;
    clear: both;
    content: "";
  }
}
.btn{
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.9rem;
  text-decoration: none;
  line-height: 1.6;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.btn-primary {
  color: #fff;
  background-color: #3490dc;
  border-color: #3490dc;
}
.silver{
  background-color: #6c757d;
  color: #fff;
}
.col-1{flex:0 0 auto;width:8.33333333%}
.col-2{flex:0 0 auto;width:16.66666667%}
.col-3{flex:0 0 auto;width:25%}
.col-4{flex:0 0 auto;width:33.33333333%}
.col-5{flex:0 0 auto;width:41.66666667%}
.col-6{flex:0 0 auto;width:50%}
.col-7{flex:0 0 auto;width:58.33333333%}
.col-8{flex:0 0 auto;width:66.66666667%}
.col-9{flex:0 0 auto;width:75%}
.col-10{flex:0 0 auto;width:83.33333333%}
.col-11{flex:0 0 auto;width:91.66666667%}
.col-12{flex:0 0 auto;width:100%}


@media (min-width:576px){
  .col-sm-auto{flex:0 0 auto;width:auto}
  .col-sm-1{flex:0 0 auto;width:8.33333333%}
  .col-sm-2{flex:0 0 auto;width:16.66666667%}
  .col-sm-3{flex:0 0 auto;width:25%}
  .col-sm-4{flex:0 0 auto;width:33.33333333%}
  .col-sm-5{flex:0 0 auto;width:41.66666667%}
  .col-sm-6{flex:0 0 auto;width:50%}
  .col-sm-7{flex:0 0 auto;width:58.33333333%}
  .col-sm-8{flex:0 0 auto;width:66.66666667%}
  .col-sm-9{flex:0 0 auto;width:75%}
  .col-sm-10{flex:0 0 auto;width:83.33333333%}
  .col-sm-11{flex:0 0 auto;width:91.66666667%}
  .col-sm-12{flex:0 0 auto;width:100%}
}
@media (min-width:768px){
  .col-md-auto{flex:0 0 auto;width:auto}
  .col-md-1{flex:0 0 auto;width:8.33333333%;}
  .col-md-2{flex:0 0 auto;width:16.66666667%;}
  .col-md-3{flex:0 0 auto;width:25%;}
  .col-md-4{flex:0 0 auto;width:33.33333333%}
  .col-md-5{flex:0 0 auto;width:41.66666667%;}
  .col-md-6{flex:0 0 auto;width:50%;}
  .col-md-7{flex:0 0 auto;width:58.33333333%}
  .col-md-8{flex:0 0 auto;width:66.66666667%;}
  .col-md-9{flex:0 0 auto;width:75%;}
  .col-md-10{flex:0 0 auto;width:83.33333333%;}
  .col-md-11{flex:0 0 auto;width:91.66666667%;}
  .col-md-12{flex:0 0 auto; width:100%;}
}
@media (min-width:992px){
  .col-lg-auto{flex:0 0 auto;width:auto}
  .col-lg-1{flex:0 0 auto;width:8.33333333%;}
  .col-lg-2{flex:0 0 auto;width:16.66666667%;}
  .col-lg-3{flex:0 0 auto;width:25%;}
  .col-lg-4{flex:0 0 auto;width:33.33333333%}
  .col-lg-5{flex:0 0 auto;width:41.66666667%;}
  .col-lg-6{flex:0 0 auto;width:50%;}
  .col-lg-7{flex:0 0 auto;width:58.33333333%}
  .col-lg-8{flex:0 0 auto;width:66.66666667%;}
  .col-lg-9{flex:0 0 auto;width:75%;}
  .col-lg-10{flex:0 0 auto;width:83.33333333%;}
  .col-lg-11{flex:0 0 auto;width:91.66666667%;}
  .col-lg-12{flex:0 0 auto; width:100%;}
  .filter-button{
    display: none;
  }
}

.content-full{
  .content-inner{
    padding-top:1.5rem;
    padding-bottom:1.5rem;
  }
}
.sidebar-list{
  mat-expansion-panel {
    background: none;
    box-shadow: none !important;
    margin-bottom: 0.5rem !important;
    margin-top: 0 !important;
    mat-expansion-panel-header {
      background: none !important;
      padding: 0.375rem 0.75rem;
      height: auto !important;
      font-size: 0.9rem;
    }
    .mat-expansion-panel-content {
      background: #fff;
      border-radius: 0.25rem;
      border: 1px solid rgba(0, 0, 0, 0.125);
      padding:0;
      .mat-expansion-panel-body {
        padding: 1.25rem;
      }
    }
  }
  .mat-mdc-checkbox {
    display: block;
    font-size: 12px;
    margin-left: -16px;
    line-height: 24px;
    .mdc-checkbox{
      max-width:11px;
      width: 11px;
      height: 11px;
      padding-top: 0;
      padding-bottom: 0;
      padding-right: 4px;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    .mdc-checkbox__background {
      width: 11px;
      height: 11px;
      border-radius: 2px;
      top: 0 !important;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
    label{
      display: flex;
      align-items: center;
      font-size: 12px;
      .mini-verify-icon {
        width: 18px;
        padding: 0 3px;
      }
    }
    img.country-img {
      width: 24px;
      margin-right: 6px;
    }
  }
}
.banner-img{
  img{
      width: 100%;
      height: auto;
  }
}
.table{
  margin-bottom: 1rem;
  table {
    background: none;
    tr{
      th{
          font-size: 0.9rem;
          border: 1px solid #dee2e6;
          padding: 0.75rem;
          color: #212529;
      }
      td{
          font-size: 0.9rem;
          border: 1px solid #dee2e6;
          padding: 0.75rem;
          vertical-align: top;
      }
    }
  }
}
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #3490dc !important;
}
.contribution-display-link{
  display:block;
  height: 20px;
  border-top: solid 5px #df3bf2;
  margin-top: 5px;
  width: 40px;
  cursor: pointer;
  text-indent: -9999px;
  line-height: 1px;
  &.chead {
    width: 100%;
    max-width: 320px;
    height: 30px;
  }
}
.content-inner{
  h2 {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
  }
  h4{
    font-size: 1.35rem;
  }
}
.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  box-sizing: border-box;
}
.mat-drawer-content {
  overflow-x: inherit !important;
}
.mat-sidenav-fixed {
  position: sticky !important;
  top: 0 !important;
  width: 290px;
  float: left;
  background: none;
  border: 0;
  padding: 20px 10px;
}
.mat-drawer-container{
  background-color: #f8fafc;
}
.mat-toolbar.mat-secondary.mat-toolbar-single-row {
  background: none !important;
  box-shadow: none;
  padding: 0 !important;
}
.select-filter {
  display: none;
}
.tooltip{
  font-size: 0.7875rem;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem !important;
  padding: 10px;
  color: #212529 !important;
  z-index: 999;
}
.create-form-search .mat-mdc-text-field-wrapper .mdc-notched-outline__notch {
  padding-right: 0px;
}
#contribution-area {
  padding: 0;
  .mat-mdc-dialog-title {
      padding: 12px 15px;
      margin: 0 !important;
      font-size: 18px !important;
      display: flex;
      align-items: center;
  }
  .mat-mdc-dialog-content {
      margin: 0;
      border-top: solid 1px #ccc;
      border-bottom: solid 1px #ccc;
      padding: 10px 15px;
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
      .mat-mdc-tab-body-wrapper {
          padding: 15px 0 0 0;
      }
  }
  .mat-mdc-dialog-actions {
      margin: 0;
      padding: 8px 15px;
  }
}
.mat-h4, .mat-body-1, .mat-typography .mat-h4, .mat-typography .mat-body-1, .mat-typography h4 {
  letter-spacing: normal;
}
.model-dialog-area {
  padding: 0;
  .mat-mdc-dialog-title {
      padding: 12px 15px;
      margin: 0 !important;
      font-size: 18px !important;
      display: flex;
      align-items: center;
  }
  .mat-mdc-dialog-content {
      margin: 0;
      border-top: solid 1px #ccc;
      border-bottom: solid 1px #ccc;
      padding: 10px 15px;
      /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
      .mat-mdc-tab-body-wrapper {
          padding: 15px 0 0 0;
      }
  }
  .mat-mdc-dialog-actions {
      margin: 0;
      padding: 8px 15px;
  }
}
.tag-field .mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align{
  display: none;
}

.tag-field .mdc-notched-outline {
  height: 100% !important;
}

.tag-field .mat-mdc-text-field-wrapper {
  height: 40px;
}

.tag-field .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex {
  height: 100% !important;
}

.tag-field .mat-mdc-form-field-infix {
  padding: 0 !important;
  line-height: 40px;
}

.search-input .mat-mdc-form-field-infix {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  min-height: 44px !important;
}
.search-input .mat-mdc-form-field-infix input {
  font-size: 14px;
}
.mat-mdc-tab-group.mat-primary{
  .mat-ink-bar {
    background-color: #df3bf2;
  }
}
[hidden]{ display: none !important;}
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.mat-checkbox-disabled .mat-checkbox-label{ color: rgba(0,0,0,.2)}
/* TODO(mdc-migration): The following rule targets internal classes of checkbox that may no longer apply for the MDC version. */
.sidebar-list .mat-checkbox-disabled .mat-checkbox-label .mini-verify-icon{ opacity: 0.2}
a{
  cursor: pointer;
}
#video-popup{
  padding: 0;
}
td.mat-mdc-cell.no-results-cell {
  background: #f7eaea;
  text-align: center;
  font-weight: bold;
  color: #dc7979;
}
.search-remove-icon {
  position: absolute !important;
  right: 5px;
  top: 9px;
  width: 14px !important;
  height: 14px !important;
  background: none !important;
  box-shadow: inherit !important;
  line-height: 14px !important;
  mat-icon.mat-icon {
    font-size: 16px;
    color: #000;
    width: 16px;
    height: 16px;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
  span.mat-button-wrapper {
    padding: 0 !important;
    line-height: 14px;
  }
}
.create-form-search .mat-mdc-form-field-infix {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    min-height: 44px !important;
}

.create-form-search .mat-mdc-text-field-wrapper {
    background: #fff;
}

.create-form-search label.mdc-floating-label {
    top: 22px !important;
    font-size: 14px;
}

.create-form-search .mat-mdc-form-field-infix mat-select span {
    font-family: "Nunito Sans", sans-serif;
}
.sidenav-close {
  text-align: right;
  padding-right: 10px;
  margin-bottom: 5px;
  display: none;
  .close-icon {
    background: none;
    padding: 0;
    border: 0;
  }
}
.back-btn mat-icon.mat-icon {
  font-size: 14px;
  line-height: 14px;
  height: 14px;
  width: 14px;
  margin-right: 2px;
}
.search-input button.search-remove-icon {
  top: 50%;
  transform: translateY(-50%);
}
.search-input .mat-mdc-text-field-wrapper {background: #fff;}
.sidebar-list .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__ripple, .sidebar-list .mat-mdc-checkbox .mdc-checkbox .mat-mdc-checkbox-ripple{
  height: 23px;
  width: 23px;
  top: -6px;
  left: 5px;
}
.ngx-mat-color-canvas .mat-form-field-label-wrapper, .ngx-mat-color-canvas .color-canvas-row .zone-inputs:last-child, .ngx-mat-color-canvas .color-canvas-row:nth-of-type(2) .mat-form-field:last-of-type, .ngx-mat-color-collection {
  display: none;
}
.mat-mdc-paginator-range-label {
  color: rgba(0,0,0,.54);
}
.mat-mdc-paginator-page-size-label {
  color: rgba(218, 214, 214, 0.54);
}
.content.full-page {
  margin: 0 auto;
  max-width: 1140px;
}
.errors{
  border: solid 1px red;
  padding: 5px 10px;
  border-radius: 4px;
  margin-bottom: 15px;
  color: red;
  p{
    margin: 0;
  }
}
mat-sidenav-content{
  .mobile-menu-icon-area {
    display: flex;
    align-items: center;
    .mobile-menu-icon-text{
      font-size: 18px;
    } 
  }
}
.mobile-menu{
  mat-icon {
    margin-right: 0 !important;
  }
}
.firework-head-img{
  width: 96px;
}
.mat-column-image, .product-img {
  a{
    display: block;
  }
  app-image {
    width: 120px;
    display: block;
    position: relative;
    &:before{
      content: "";
      padding-top: 100%;
      display: block;
    }
    img{
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
.product-img{
  app-image {
    width: 100%;
  }
}
@media (max-width:1200px){
    .login-page{
      .card-body{
        padding: 20px !important;
        .social-icons{
          .row{
            display: block !important;
            .login-icon{
              justify-content: unset !important;
              margin-bottom: 10px;
              width: 100% !important;
            }
          }
        }
    }
  }
}
@media (max-width:992px){
  .mat-typography h3,h3{
    font-size: 1.15em !important;
  }
  button.filter-button {
    color: rgba(0, 0, 0, 0.5);
    background-color: transparent;
    border: solid 1px rgba(0, 0, 0, 0.1);
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    padding: 0.25rem 0.75rem;
    font-size: 1rem;
  }
  .mat-toolbar.mat-secondary.mat-toolbar-single-row {
    background: #eee !important;
    padding: 0.5rem 1rem !important;
    flex-direction: column;
    align-items: self-start;
    /* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
    span.mat-button-wrapper {
      font-weight: normal;
      font-size: 16px;
      mat-icon {
        margin-right: 5px;
      }
    }
  }
  .logo{
    img {
      max-width: 110px;
    }
  }
  mat-chip-listbox {
    margin-bottom: 10px;
  }
}
@media (max-width:959px){
  .mat-sidenav-fixed {
    position: fixed !important;
    width: auto;
    float: none;
    background: #fff;
  }
  .select-filter {
    display: block;
  }
  .sidenav-close {
    display: block;
  }
}
@media (max-width:767px){
  .create-form-search {
    display: block;
    .mat-mdc-form-field {
      display: block;
    }
  }
  button.filter-button {
    background: #333;
    color: #fff;
    font-size: 0.8rem;
    padding: 6px 10px;
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
  }
  .content-inner h2 {
    font-size: 1.3rem;
  }
  .contribution-display-link.chead {
      height: 10px;
  }
  .content-inner img {
    width: 100%;
    object-fit: contain;
  }
  .create-form-search {
    button {
      margin-bottom: 5px;
    }
  }
  .login-page{
    .card-body{
      .social-icons{
        .row{
          .login-icon {
            margin-bottom: 15px;
            box-shadow: 7px 3px 13px #ddd;
            padding: 11px 15px;
            border-radius: 8px;
            max-width: 229px;
            margin: 0 auto 20px;
            span{
              font-size: 16px !important;
              margin-left: 15px !important;
            }
          }
        }
      }
    }
  }
}
@media (max-width:576px){
  .add-new-fireworks-box{
    display: block;
  }
  .add-new-fireworks-box p {
    margin: 0px;
  }
  .custom-add-btn{
    margin-bottom: 10px;
  }
}
@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm,
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}
